import { Injectable } from '@angular/core';

@Injectable()
export class StatusConstant {
    deliveries: object;
    orders: object;
    spots: object;

    constructor() {
        this.deliveries = {
            PENDING_MEDIA: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Pending Media',
                progress: undefined,
                label: 'The spot media has not yet been uploaded.',
            },
            PENDING_PROD_SVCS: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Pending Prod Svcs',
                progress: undefined,
                label:
                    'The spot has production services selected but has not been sent to production services.',
            },
            PENDING_SPOT_METADATA: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Pending Metadata',
                progress: undefined,
                label: 'The mandatory spot metadata has not yet been provided.',
            },
            PENDING_TRAFFIC: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Pending Traffic',
                progress: undefined,
                label: 'This delivery is waiting for traffic to be attached.',
            },
            PENDING_QC: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Pending QC',
                progress: undefined,
                label: 'The spot media for this specific delivery is awaiting manual QC.',
            },
            VERIFYING: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 2,
                status: 'Verifying',
                progress: 0,
                label: 'The spot is waiting to be prepared.',
            },
            WAITING: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 2,
                status: 'Waiting',
                progress: undefined,
                label: 'This delivery is waiting to submit until a later time.',
            },
            PENDING_TRANSCODE: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 2,
                status: 'Pending Transcode',
                progress: undefined,
                label:
                    'Waiting for a transcoder to become available so that this spot can be converted to a usable format.',
            },
            TRANSCODING: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 2,
                status: 'Transcoding',
                progress: 0,
                label: 'The spot is being converted to a usable format.',
            },
            PARKED: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 2,
                status: 'Traffic Review',
                progress: undefined,
                label:
                    'This delivery is currently waiting for traffic to be reviewed before it continues on.',
            },
            QUEUED_FOR_DELIVERY: {
                color: 'rgb(218, 165, 32)',
                total: 4,
                current: 3,
                status: 'Queued for Delivery',
                progress: undefined,
                label:
                    'The spot is waiting to be transferred to the destination.  If a delivery is queued for an extended period of time, the destination may be temporarily offline.',
            },
            DELIVERING: {
                color: 'rgb(218, 165, 32)',
                total: 4,
                current: 3,
                status: 'Delivering',
                progress: 0,
                label: 'The delivery is being sent to its destination.',
            },
            WAITING_FOR_ACCEPTANCE: {
                color: 'rgb(218, 165, 32)',
                total: 4,
                current: 3,
                status: 'Waiting for Acceptance',
                progress: undefined,
                label: 'Click Accept Promo(s) for Delivery to finalize delivery of this spot.',
            },
            SHIPPING: {
                color: 'rgb(218, 165, 32)',
                total: 4,
                current: 3,
                status: 'Shipping',
                progress: undefined,
                label: 'The delivery is being sent to its destination.',
            },
            CANCELLED: {
                color: 'rgb(185, 0, 0)',
                total: 4,
                current: 3,
                status: 'Canceled',
                progress: undefined,
                label: 'The spot upload or processing has been canceled by the system.',
            },
            REMOVED_FROM_ORDER: {
                color: 'rgb(185, 0, 0)',
                total: 4,
                current: 3,
                status: 'Removed From Order',
                progress: undefined,
                label: 'This delivery has been removed from its order.',
            },
            COMPLETED: {
                color: 'rgb(0, 150, 64)',
                total: 4,
                current: 4,
                status: 'Completed',
                progress: undefined,
                label: 'The spot has fully transferred to the destination and is now complete.',
            },
            DOWNLOAD_AVAILABLE: {
                color: 'rgb(0, 150, 64)',
                total: 4,
                current: 4,
                status: 'Download Available',
                progress: undefined,
                label: 'This delivery can now be downloaded.',
            },
            EXPIRED: {
                color: 'rgb(185, 0, 0)',
                total: 4,
                current: 4,
                status: 'Expired',
                progress: undefined,
                label: 'This delivery is no longer valid.',
            },
            FAILED: {
                color: 'rgb(185, 0, 0)',
                total: 4,
                current: 4,
                status: 'Failed',
                progress: undefined,
                label:
                    'The spot delivery has failed due to an internal system problem. Please send the spot again.',
            },
        };
        this.orders = {
            DRAFT: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(159,80,149)',
                total: 4,
                current: 0,
                status: 'Draft',
                progress: undefined,
                label: 'Order is currently in draft',
            },
            WAITING: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Waiting',
                progress: 0,
                label: 'This order is waiting to be submitted at a later time',
            },
            PENDING_MEDIA: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(252,166,3)',
                total: 4,
                current: 2,
                status: 'Pending Media',
                progress: undefined,
                label:
                    'The spot media necessary for one or more deliveries in this order has not yet been uploaded.',
            },
            PENDING_SPOT_METADATA: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(255,124,67)',
                total: 4,
                current: 2,
                status: 'Pending Spot Metadata',
                progress: undefined,
                label:
                    'The mandatory spot metadata for one or more deliveries in this order has not yet been provided',
            },
            PENDING_TRAFFIC: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(212,80,135)',
                total: 4,
                current: 2,
                status: 'Pending Traffic',
                progress: undefined,
                label: 'This order is waiting for its traffic to be uploaded',
            },
            SUBMITTED: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(101,81,145)',
                total: 4,
                current: 2,
                status: 'Submitted',
                progress: undefined,
                label: 'This order has been submitted',
            },
            WAITING_FOR_ACCEPTANCE: {
                color: 'rgb(218, 165, 32)',
                total: 4,
                current: 3,
                status: 'Waiting for Acceptance',
                progress: undefined,
                label: 'This order is waiting for acceptance',
            },
            QUEUED: {
                color: 'rgb(218, 165, 32)',
                total: 4,
                current: 3,
                status: 'Queued',
                progress: undefined,
                label: 'This order is currently in the queue',
            },
            ACTIVE: {
                color: 'rgb(0, 150, 64)',
                dashboardColor: 'rgb(0,150,150)',
                total: 4,
                current: 3,
                status: 'Active',
                progress: undefined,
                label: 'This order is currently active',
            },
            COMPLETED: {
                color: 'rgb(0, 150, 64)',
                dashboardColor: 'rgb(61,96,159)',
                total: 4,
                current: 4,
                status: 'Completed',
                progress: undefined,
                label: 'The order has been completed with no failures of any deliveries.',
            },
            CANCELLING: {
                color: 'rgb(185, 0, 0)',
                total: 4,
                current: 4,
                status: 'Cancelling',
                progress: -1,
                label: 'This order is being canceled',
            },
            CANCELLED: {
                color: 'rgb(185, 0, 0)',
                dashboardColor: 'rgb(21,29,86)',
                total: 4,
                current: 4,
                status: 'Canceled',
                progress: undefined,
                label: 'The order has been canceled by the system or by the user.',
            },
            FAILED: {
                color: 'rgb(185, 0, 0)',
                dashboardColor: 'rgb(222,67,79)',
                total: 4,
                current: 4,
                status: 'Failed',
                progress: undefined,
                label:
                    'The order has failed because one or more deliveries in the order have failed. Other deliveries in the order may have successfully completed.',
            },
        };
        this.spots = {
            DRAFT: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 0,
                status: 'Draft',
                progress: undefined,
                label:
                    'Spot is currently in a draft state and will not exist in the system until it is saved',
            },
            UPLOADING: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(101,80,145)',
                total: 4,
                current: 0,
                status: 'Uploading',
                progress: 0,
                label: 'The spot is being uploaded.',
            },
            UPLOADED: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 1,
                status: 'Uploaded',
                progress: undefined,
                label: 'The spot media has successfully uploaded, and is now being submitted',
            },
            VERIFYING: {
                color: 'rgb(120, 120, 120)',
                total: 4,
                current: 2,
                status: 'Verifying',
                progress: 0,
                label: 'The spot is waiting to be prepared.',
            },
            PROCESSING: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(101,80,145)',
                total: 4,
                current: 2,
                status: 'Processing',
                progress: 0,
                label: 'The spot is being prepared.',
            },
            UPLOADING_PROCESSING: {
                color: 'rgb(120, 120, 120)',
                dashboardColor: 'rgb(101,80,145)',
                status: 'Uploading/Processing',
            },
            PENDING_MEDIA: {
                color: 'rgb(218, 165, 32)',
                dashboardColor: 'rgb(255,166,2)',
                total: 4,
                current: 3,
                status: 'Pending Media',
                progress: undefined,
                label: 'The spot media has not yet been uploaded.',
            },
            PENDING_META: {
                color: 'rgb(218, 165, 32)',
                dashboardColor: 'rgb(254,125,66)',
                total: 4,
                current: 3,
                status: 'Pending Metadata',
                progress: undefined,
                label: 'The mandatory spot metadata has not yet been provided.',
            },
            PENDING_QC: {
                color: 'rgb(218, 165, 32)',
                dashboardColor: 'rgb(160,80,150)',
                total: 4,
                current: 3,
                status: 'Pending QC',
                progress: undefined,
                label: 'The spot is awaiting manual QC.',
            },
            PENDING_PROD_SVCS: {
                color: 'rgb(218, 165, 32)',
                dashboardColor: 'rgb(212,81,135)',
                total: 4,
                current: 3,
                status: 'Pending Prod Svcs',
                progress: undefined,
                label:
                    'The spot has production services selected but has not been sent to production services.',
            },
            SENDING: {
                color: 'rgb(218, 165, 32)',
                dashboardColor: 'rgb(61,95,158)',
                total: 4,
                current: 3,
                status: 'Sending',
                progress: undefined,
                label: 'The spot is actively being delivered to one or more destinations.',
            },
            REJECTED: {
                color: 'rgb(185, 0, 0)',
                dashboardColor: 'rgb(222,66,80)',
                total: 4,
                current: 3,
                status: 'Rejected',
                progress: undefined,
                label:
                    'The spot has been rejected because it did not meet the file format specifications.',
            },
            CANCELLED: {
                color: 'rgb(185, 0, 0)',
                dashboardColor: 'rgb(20,29,86)',
                total: 4,
                current: 3,
                status: 'Canceled',
                progress: undefined,
                label: 'The spot upload or processing has been canceled by the system.',
            },
            UNSENT: {
                color: 'rgb(0, 150, 64)',
                dashboardColor: 'rgb(0,150,150)',
                total: 4,
                current: 4,
                status: 'Unsent',
                progress: undefined,
                label:
                    'The spot has not yet been delivered as part of an order but is otherwise ready to be sent as part of one or more orders.',
            },
            SENT: {
                color: 'rgb(0, 150, 64)',
                dashboardColor: 'rgb(61,95,158)',
                total: 4,
                current: 4,
                status: 'Sent',
                progress: undefined,
                label:
                    'The spot has been delivered as part of an order to at least one destination. The spot may be re-used as part of another order.',
            },
            SENDING_SENT: {
                color: 'rgb(0, 150, 64)',
                dashboardColor: 'rgb(61,95,158)',
                total: 4,
                current: 4,
                status: 'Sending/Sent',
            },
        };
    }
}
