import { Injectable } from '@angular/core';

@Injectable()
export class DashboardConstant {
    delivery: object;
    order: object;
    spot: object;

    constructor() {
        this.delivery = {
            'ISCI/Ad-ID/Spot #': {
                param: 'isci',
            },
            Status: {
                param: 'deliveryStatus',
            },
            Duration: {
                param: 'duration',
            },
            Format: {
                param: 'format',
            },
            Title: {
                param: 'title',
            },
            Destination: {
                param: 'destinationName',
            },
            'Order #': {
                param: 'orderNumber',
            },
            Advertiser: {
                param: 'advertiser',
            },
            Agency: {
                param: 'agency',
            },
            Brand: {
                param: 'brand',
            },
            'Running Footage': {
                param: 'runningFootage',
            },
            'House ID': {
                param: 'houseId',
            },
            Promos: {
                param: 'promo',
            },
            'Include Archives': {
                param: 'includeArchives',
            },
            updatedDateFrom: {
                param: 'updatedDateFrom',
            },
            updatedDateTo: {
                param: 'updatedDateTo',
            },
        };
        this.order = {
            'Order Name': {
                param: 'orderName',
            },
            'PO #': {
                param: 'poNumber',
            },
            'Order Number': {
                param: 'orderNumber',
            },
            Status: {
                param: 'OrdersStatus',
            },
            'Order Type': {
                param: 'orderType',
            },
            'Priority Type': {
                param: 'deliveryPriority',
            },
            'Call Letter/Syscode': {
                param: 'multiCallLetter',
            },
            'ISCI/Ad-ID/Spot #': {
                param: 'isci',
            },
            receivedDateFrom: {
                param: 'receivedDateFrom',
            },
            receivedDateTo: {
                param: 'receivedDateTo',
            },
            updatedDateFrom: {
                param: 'updatedDateFrom',
            },
            updatedDateTo: {
                param: 'updatedDateTo',
            },
            Agency: {
                param: 'agencyId',
            },
            Advertiser: {
                param: 'advertiserId',
            },
            Brand: {
                param: 'brandId',
            },
            Campaign: {
                param: 'campaignId',
            },
            'Estimate #': {
                param: 'estimateNumber',
            },
            'Job #': {
                param: 'jobNumber',
            },
            'Include Archives': {
                param: 'includeArchives',
            },
        };
        this.spot = {
            'ISCI/Ad-ID/Spot #': {
                param: 'isci',
            },
            Title: {
                param: 'title',
            },
            Format: {
                param: 'format',
            },
            Status: {
                param: 'SpotsStatus',
            },
            'Order #': {
                param: 'orderNumber',
            },
            Description: {
                param: 'notes',
            },
            Promos: {
                param: 'promo',
            },
            Duration: {
                param: 'duration',
            },
            createdDateFrom: {
                param: 'createdDateFrom',
            },
            createdDateTo: {
                param: 'createdDateTo',
            },
            updatedDateFrom: {
                param: 'updatedDateFrom',
            },
            updatedDateTo: {
                param: 'updatedDateTo',
            },
            validDateFrom: {
                param: 'validFrom',
            },
            validDateTo: {
                param: 'validTo',
            },
            Agency: {
                param: 'agencyId',
            },
            Advertiser: {
                param: 'advertiserId',
            },
            Brand: {
                param: 'brandId',
            },
            'Include Archives': {
                param: 'includeArchives',
            },
        };
    }
}
