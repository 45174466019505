import { Injectable } from '@angular/core';

interface PermissionProp {
    checkTypes: any,
    authorized: any
}

interface SessionProps {
    'ACCOUNT_HASH': string,
    'BRAND_INFO': string,
    'BRAND_LOGO': string,
    'CURRENT_ACCOUNT_TYPE': string,
    'LAST_LOGIN_USERNAME_KEY': string,
    'SESSION_DATA': string,
    'SESSION_KEY_NAME': string,
}

@Injectable()
export class AuthConstant {
    permission: PermissionProp;
    userRoles: object;
    accountTypes: object;
    session: SessionProps;

    constructor() {
        this.permission = {
            checkTypes: {
                AtLeastOne: 'AtLeastOne',
                AllOfThem: 'AllOfThem',
            },
            authorized: {
                authorized: 'authorized',
                unauthorized: 'unauthorized',
                loginRequired: 'loginRequired',
            },
        };
        this.userRoles = {
            systemAdmin: 'System Admin',
            footprint: 'Footprint Team',
            clientManager: 'Client Managers',
            support: 'Support',
            user: 'User',
        };
        this.accountTypes = {
            provider: 'PROVIDER',
            fulfillment: 'FULFILLMENT',
            receiver: 'RECEIVER',
            qcvendor: 'QC_VENDOR',
            prodsvcs: 'PRODUCTION_SERVICES_VENDOR',
        };
        this.session = {
            'ACCOUNT_HASH': '__addelivery.accounthash',
            'BRAND_INFO': '__brandInfo',
            'BRAND_LOGO': '__brandLogo',
            'CURRENT_ACCOUNT_TYPE': '__currentAccountType',
            'LAST_LOGIN_USERNAME_KEY': 'lastLoggedInUserName',
            'SESSION_DATA': '__adDeliveryData',
            'SESSION_KEY_NAME': '__sessionKey',
        };
    }
}
