'use strict';

import { leftNavController } from './app/common/navigation/leftNavbar-controller';
angular.module('comcast-addelivery-constants', []).constant('addelivery.state.constants', {
  mainNav: {
    template: require('./app/common/navigation/standardNavbar-template.html'),
    controller: 'standardNavController',
    controllerAs: 'vm'
  },
  subNav: {
    template: require('./app/common/navigation/subNavbar-template.html'),
    controller: 'subNavController',
    controllerAs: 'vm'
  },
  leftNav: {
    template: require('./app/common/navigation/leftNavbar-template.html'),
    controller: leftNavController,
    controllerAs: 'vm'
  },
  loginNav: {
    template: require('./app/common/navigation/loginNavbar-template.html'),
    controller: 'loginNavController',
    controllerAs: 'vm'
  }
});
angular.module('comcast-addelivery-constants').constant('addelivery.event.constants', {
  userDetail: {
    save: 'user.detail.save',
    reset: 'user.detail.reset',
    saveAndAdd: 'user.detail.saveAndAdd'
  },
  userSearch: {
    exportSearch: 'user.search.exportSearch'
  },
  defaultMediaPrefsDetail: {
    save: 'defaultediaPrefs.detail.save',
    reset: 'defaultMediaPrefs.detail.reset'
  },
  destinationDetail: {
    save: 'destination.detail.save',
    reset: 'destination.detail.reset',
    saveAndAddAnother: 'destination.detail.saveAndAddAnother'
  },
  agencyDetail: {
    save: 'agency.detail.save',
    reset: 'agency.detail.reset'
  },
  xferclientDetail: {
    save: 'xferclient.detail.save',
    reset: 'xferclient.detail.reset'
  },
  spotDetail: {
    save: 'spot.detail.save'
  },
  orderDetail: {
    save: 'order.detail.save',
    copy: 'order.detail.copy'
  },
  settingsDestinationSearch: {
    exportSearch: 'settings.destinations.search.exportSearch'
  }
});
angular.module('comcast-addelivery-constants').constant('addelivery.channels.constants', {
  MAPPING_MULTICH_CENTER: 'MULTICH_CENTER',
  MAPPING_MULTICH_FRONT_LEFT: 'MULTICH_FRONT_LEFT',
  MAPPING_MULTICH_FRONT_RIGHT: 'MULTICH_FRONT_RIGHT',
  MAPPING_MULTICH_LFE: 'MULTICH_LFE',
  MAPPING_MULTICH_SURROUND_LEFT: 'MULTICH_SURR_LEFT',
  MAPPING_MULTICH_SURROUND_RIGHT: 'MULTICH_SURR_RIGHT',
  MAPPING_STEREO_LEFT: 'STEREO_LEFT',
  MAPPING_STEREO_RIGHT: 'STEREO_RIGHT',
  MAPPING_SILENCE: 'SILENCE',
  MAPPING_SAP_SILENCE: 'SAP_SILENCE',
  MAPPING_VDS_SILENCE: 'VDS_SILENCE',
  MAPPING_ORG_FRONT_LEFT: 'ORG_FRONT_LEFT',
  MAPPING_ORG_FRONT_RIGHT: 'ORG_FRONT_RIGHT',
  MAPPING_ORG_CENTER: 'ORG_CENTER',
  MAPPING_ORG_LFE: 'ORG_LFE',
  MAPPING_ORG_SURROUND_LEFT: 'ORG_SURR_LEFT',
  MAPPING_ORG_SURROUND_RIGHT: 'ORG_SURR_RIGHT'
});